/**
 * @author Sergey Tumarkin https://t.me/stumarkin
 */
import { 
    Alert,
    Card,
    Button,
    Table, 
    Form,
    InputGroup,
    Stack,
    Container,
    Row,
    Col,
    Spinner
 } from 'react-bootstrap';
import { useState, useEffect, useCallback, forwardRef } from 'react';
import { Link, useParams, useNavigate } from "react-router-dom"
import * as API from '../data/API';

const formatDate = (dateStr) => {
    const date = new Date(dateStr);
    const currentDate = new Date();
    const yesterday = new Date(currentDate);
    yesterday.setDate(currentDate.getDate() - 1);

    const padZero = (num) => (num < 10 ? '0' + num : num);
    const formattedTime = `${padZero(date.getHours())}:${padZero(date.getMinutes())}`;

    if (date.toDateString() === currentDate.toDateString()) {
        return 'Сегодня<br>' + formattedTime
    } else if (date.toDateString() === yesterday.toDateString()) {
        return 'Вчера<br>' + formattedTime
    } else {
        return `${padZero(date.getDate())}&nbsp;${getMonthName(date.getMonth())}<br>${formattedTime}`
    }
}

const getMonthName = (month) => {
    const months = ['января', 'февраля', 'марта', 'апреля', 'мая', 'июня', 'июля', 'августа', 'сентября', 'октября', 'ноября', 'декабря'];
    return months[month];
}

export default function Header (props){
    const navigate = useNavigate();

    const [activePlan, setActivePlan] = useState({id:0, limit_users:0})
    const [AccountsSummary, setAccountsSummary] = useState([])
    let { form } = useParams();

    const getAccountsSummary = ( ) => {
        // setIsLoading(true)
        API.Get({ method:'getaccountssummary' })
        .then(({data}) => {
            if (data.result){
                setAccountsSummary( data.accounts_summary );
                console.log(data.accounts_summary )
            }
        })
        // .finally(()=>setIsLoading(false))
    }

    useEffect(() => {
        getAccountsSummary()
    },[])


    return (
        <>
            {/* <h1 className="my-4">Обзор</h1> */}

            
                        
                        <div className="row">
                            <div className="col-xl-12 col-md-6">
                                    <Alert variant="secondary" className='mb-3'>
                                        Приложение запущено в тестовом режиме, при выяалении ошибок, пишите на <a href="https://t.me/stumarkin">@stumarkin</a>
                                    </Alert>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-xl-12 col-md-6">
                            {
                                !activePlan.id ? (
                                    <Alert variant="success" className='mb-3'>
                                        <Alert.Heading className='display-5'>Добро пожаловать 👋 </Alert.Heading>
                                        Компания успешно зарегистрирована.<br/>
                                        Вы уже можете пользоваться панелью управления Приёмка Про в ознакомительных целях.<br/>
                                        Для полноценной работы завершите шаги, указанные ниже.<br/>
                                    </Alert>
                                ):null
                            }
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-xl-4 col-md-6">
                                <Alert variant="warning">
                                    <Alert.Heading>
                                        <Spinner animation="grow" variant="warning" size="sm" className='mb-1 me-2'/>
                                        Шаг 1. Приложение 
                                    </Alert.Heading>
                                    <p>
                                        Установите мобильное приложение для проведения приёмки на объекте.<br/>Войти в приложение можно с теми же учетными данными, что и в Панель управления. Они уже у вас на почте.
                                    </p>
                                    <hr />
                                    <div className="d-flex justify-content-end">
                                        <Button variant="outline-dark" href="https://priemka-pro.ru/app/" target='_blank'>
                                            Ссылка и QR
                                        </Button>
                                    </div>
                                </Alert>
                            </div>

                            <div className="col-xl-4 col-md-6">
                                <Alert variant="light">
                                    <Alert.Heading>Шаг 2. Тариф</Alert.Heading>
                                    <p>
                                        Выберете и оплатите тариф исходя из текущего кол-ва сотрудников и ежемячного объема приёмок. Обратите внимание на доступность функций в разных тарифах. Тариф можно сменить мозже.
                                    </p>
                                    <hr />
                                    <div className="d-flex justify-content-end">
                                        <Button variant="outline-dark">
                                            <Link className="nav-link" to="/plans">
                                                Подключить тариф
                                            </Link>
                                        </Button>
                                    </div>
                                </Alert>
                            </div>

                            <div className="col-xl-4 col-md-6">
                                <Alert variant="light">
                                    <Alert.Heading>Шаг 3. Настройки</Alert.Heading>
                                    <p>
                                            Добавьте специалистов; загрузите логотип и реквизиты компании, сканы лицензий для форм Акта осмотра и Заключения.
                                            Для опытных специалистов доступны настройки структры и содержания самой приёмки.
                                    </p>
                                    <hr />
                                    <div className="d-flex justify-content-end">
                                        <Button variant="outline-dark">
                                            <Link className="nav-link" to="/settings-report">
                                                Изменить настройки отчета
                                            </Link>                                        
                                        </Button>
                                    </div>
                                </Alert>
                            </div>
                        </div>

                        <div className="row">

                            {
                            AccountsSummary.length > 0 ? (
                                    <Table striped hover>
                                        <thead>
                                            <tr>
                                                <th></th>
                                                <th>Приёмок</th>
                                                <th>Авторизаций</th>
                                                <th>Создан</th>
                                                <th>Пользователь</th>
                                                <th>app / my</th>
                                                <th>Пришел в</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                        {
                                            AccountsSummary.map( ({
                                                account_id,
                                                name,
                                                date_insert,
                                                forms_count_account,
                                                auth_count,
                                                userid,
                                                accountid,
                                                username,
                                                is_admin,
                                                auth_app,
                                                auth_my,
                                                first_auth_app,
                                                signup_link,
                                                forms_count_user

                                            }) => (
                                                <tr onClick={(e)=> navigate(`/dashboard/accounts/${account_id}`) } className=''>
                                                    <td>[{account_id}] {name}</td>
                                                    <td>{forms_count_account}</td>
                                                    <td>{auth_count}</td>
                                                    <td className='d-none d-md-table-cell' dangerouslySetInnerHTML={{ __html: formatDate(date_insert) }}></td>
                                                    <td>[{userid}] {
                                                        is_admin > 0 ? <a href={signup_link}>{username}</a> : username
                                                    }</td>
                                                    <td>{auth_app > 0 ? '🟢' : '🔘'} / {auth_my > 0 ? '🟢' : '🔘'}</td>
                                                    <td>{first_auth_app}</td>
                                                </tr>
                                            ) )
                                        }
                                        </tbody>
                                    </Table>
                                ) : null
                            }
                        </div>
                        
        </>
    )
}