/**
 * @author Sergey Tumarkin https://t.me/stumarkin
 */
import { 
    Alert,
    FloatingLabel, 
    Form,
    Button,
    Card,
    Badge,
    ListGroup,
    Modal
 } from 'react-bootstrap';
import * as API from '../data/API';
import { useState, useEffect, useCallback, useRef } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';



export default function SettingsForm ( props ){
    const {
        authtoken,
        username
    } = props

    const [isLoading, setIsLoading] = useState(false)
    
    const [plans, setPlans] = useState([])
    const [transactions, setTransactions] = useState([])
    const [activePlan, setActivePlan] = useState({id:0})
    const [showCard, setShowCard] = useState(false);
    
    const [showHelp, setShowHelp] = useState(false);
    const toggleShowHelp = () => setShowHelp(!showHelp);
    
    const [showGetOffer, setShowGetOffer] = useState(false);
    const toggleShowGetOffer = () => setShowGetOffer(!showGetOffer);


    useEffect(() => {
        getPlans()
        getBillingTransactions()
    }, [])

    const getPlans = () => {
        setIsLoading(true)
        API.Get({ method:'getplans', authtoken})
        .then(res => {
            const {result, plans} = res.data
            if ( result && plans ){
                setPlans( plans )

                API.Get({ method:'getaccountplan', authtoken})
                .then(({data}) => {
                    console.log(data.plan);
                    if (data.plan){
                        setActivePlan( data.plan )
                    }
                })
            }
        })
        .catch( err => alert(err))
        .finally(()=>setIsLoading(false))
    }
    
    const getBillingTransactions = () => {
        setIsLoading(true)
        API.Get({ method:'getbillingtransactions', authtoken})
        .then(({data}) => {
            const {result, transactions} = data
            console.log(transactions);
            if ( result && transactions ){
                setTransactions( transactions )
            }
        })
        .catch( err => alert(err))
        .finally(()=>setIsLoading(false))
    }

    const statusBadge = ( status ) => {
        switch (status) {
            case 'succeeded':
            case 'waiting_for_capture':
              return (<Badge bg="success" pill>Успешно</Badge>)
              break;

            case 'draft':
            case 'pending':
              return (<Badge bg="warning" pill>Ожидает</Badge>)
              break;

            case 'canceled':
              return (<Badge bg="danger" pill>Отменен</Badge>)
              break;

            default:
            return (<Badge bg="warning" pill>{status}</Badge>)

          }

    }
    
    const getMonthName = (month) => {
        const months = ['января', 'февраля', 'марта', 'апреля', 'мая', 'июня', 'июля', 'августа', 'сентября', 'октября', 'ноября', 'декабря'];
        return months[month];
    }

    const formatDate = (dateStr) => {
        const date = new Date(dateStr);
        const currentDate = new Date();
        const yesterday = new Date(currentDate);
        yesterday.setDate(currentDate.getDate() - 1);
    
        const padZero = (num) => (num < 10 ? '0' + num : num);
        const formattedTime = `${padZero(date.getHours())}:${padZero(date.getMinutes())}`;
    
        if (date.toDateString() === currentDate.toDateString()) {
            return 'Сегодня ' + formattedTime
        } else if (date.toDateString() === yesterday.toDateString()) {
            return 'Вчера ' + formattedTime
        } else {
            return `${date.getDate()} ${getMonthName(date.getMonth())} ${formattedTime}`
        }
    }

    const getNextDay = (dateString) => {
        const date = new Date(dateString);
        date.setDate(date.getDate() + 1);
        const options = { day: 'numeric', month: 'long' };
        return date.toLocaleDateString('ru-RU', options);
    }

    const isFutureDateTime = (dateTimeString) => {
        const dateTime = new Date(dateTimeString);
        const now = new Date();
        return dateTime > now;
    }

    const toggleAutopay = ( autopay ) => {
        API.Post({ method:'toggleautopay', authtoken}, { autopay })
        .then(({data}) => {
            console.log(data)
            if ( data.result ){
                setActivePlan({...activePlan, autopay })
            }
        })
    }

    const billingTransactions = () => (
        <>
            {
                transactions.length >1 &&
                (
                    <>
                        <h2>Платежи</h2>
                        <ListGroup className='mt-3'>
                            {
                                isFutureDateTime(activePlan?.date_plan_end) ? (
                                    activePlan?.autopay==1 ? (
                                        <ListGroup.Item className="d-flex justify-content-between align-items-start">
                                            <div className="ms-2 me-auto">Следующая оплата будет автоматически списана {getNextDay((transactions.filter(({paid})=>paid==1))[0]?.date_plan_end)} c карты <div className='d-inline-flex text-primary' style={{cursor:'pointer'}} onClick={()=>setShowCard(true)}>*{activePlan?.paycard.last4}</div></div>
                                            <Button variant="link" className='text-secondary btn-sm'
                                                onClick={()=>{
                                                    if (window.confirm('Подписка перестанет продлеваться автоматически и сервис перестанет быть доступен после окончания текущего оплаченного периода.\n\nТочно отменяем?')) {
                                                        toggleAutopay(0)
                                                    } 
                                                }}
                                            >
                                                Отменить
                                            </Button>
                                        </ListGroup.Item>
                                    ) : ( 
                                        <ListGroup.Item className="d-flex text-danger justify-content-between align-items-start">
                                            <div className="ms-2 me-auto">Автопродление отменено<br/>{getNextDay((transactions.filter(({paid})=>paid==1))[0]?.date_plan_end)} доступ к функциям приложения будет ограничен </div>
                                            <Button variant="primary" className='btn-sm'
                                                onClick={()=>{
                                                    toggleAutopay(1)
                                                }}
                                            >
                                                Возобновить автопродление
                                            </Button>
                                        </ListGroup.Item>
                                    )
                                ) : null
                            }
                        </ListGroup>
                        <ListGroup className='mt-3'>
                            { transactions.map( transaction => (
                                <ListGroup.Item
                                    className="d-flex justify-content-between align-items-start"
                                >
                                    <div className="ms-2 me-auto">
                                        <div className={ transaction.paid==0 ? "text-secondary" : null }>
                                            <div className="fw-bold">
                                               { formatDate( transaction.date_insert )}, { transaction.sum }₽
                                            </div>
                                            { transaction.invoice_subject }
                                        </div>
                                    </div>
                                    {statusBadge(transaction.status)}
                                </ListGroup.Item>
                            ))}
                        </ListGroup>
                    </>
                )
            }
        </>
    )

    const createPayment = (data) => {
        setIsLoading(true)
        API.Post({ method:'createpayment', authtoken}, data)
        .then(res => {
            console.log( res.data )
            const {result, payment} = res.data
            if (result && payment){
                const {username, billing_id, description, account_id, value} = payment
                axios.post( 
                    'https://priemka-pro.ru/api/yookassa/index.php', 
                    { username, billing_id, description, account_id, value }, 
                    { headers: { 'Content-Type': 'multipart/form-data'} }
                )
                .then( (res)=>{
                  console.log(res.data);
                  if (res.data.confirmationUrl){
                      window.location.replace(res.data.confirmationUrl);
                  }
                })
            }
        })
        .finally(()=>setIsLoading(false))
    }

    const inclineWord = ( howMany, ofWhat, humanicStyle = false ) => {
        switch (ofWhat){
            case "пользователь":
                if ([10,11,12,13,14].includes(parseInt(howMany))){
                    return `${howMany} пользователей`;
                }
                switch ( howMany - (Math.floor(howMany/10)*10) ){
                    case 0: if (humanicStyle) {return `Безлимит пользователей`}
                    case 1: return `${howMany} пользователь`
                    case 2:
                    case 3:
                    case 4: return `${howMany} пользователя`
                    default: return `${howMany} пользователей`
                }
          
            case "приёмка / месяц":
                if ([11,12,13,14].includes(parseInt(howMany))){
                    return `${howMany} приёмок / месяц`;
                }
                if (howMany==0 && humanicStyle){
                    return `Безлимит приёмок`;
                }
                switch ( howMany - (Math.floor(howMany/10)*10) ){
                    case 1: return `${howMany} приёмка / месяц`
                    case 2:
                    case 3:
                    case 4: return `${howMany} приёмки / месяц`
                    default: return `${howMany} приёмок / месяц`
                }
            default: return `${howMany} ${ofWhat}`
        }
    }

    const PlanCard = (plan) => {
        const {id, is_public, is_monthly,  is_featured, name, price, limit_users, limit_forms_per_month, limit_photo_per_form, feature_comments, feature_offline, feature_photo, priority_support} = plan
        return (
            <div className="card mb-4">
                <div className="card-body p-4">
                    <div className="small text-uppercase fw-bold text-muted">
                        <i className={`fas fa-star text-warning ${(!+is_featured ? 'd-none' : '')}`}></i> {name}
                    </div>
                    <div className="mb-3">
                        <span className="display-6 fw-bold">{!+is_monthly && 'от '} {price.slice(0, -3)} <span className="small">{price.slice(-3)}</span></span>
                        <span className="text-muted"> ₽ {!!+is_monthly && '/ месяц'}</span>
                    </div>
                    <ul className="list-unstyled mb-4">
                        {
                            !+is_monthly ? (
                                <>
                                    <li className="mb-2">
                                        <i className="bi bi-check text-primary"></i>
                                        <strong>Индивидуальные доработки</strong>
                                    </li>
                                    <li className="mb-2">
                                        <i className="bi bi-check text-primary"></i>
                                        <strong>Бессрочное использование</strong>
                                    </li>
                                    <li className="mb-2"><i className="fas fa-check text-primary"></i> Все возможности тарифа Бизнес</li>
                                    <li className="mb-2"><i className="fas fa-check text-primary"></i> Отдельное приложение в сторах</li>
                                    <li className="mb-2"><i className="fas fa-check text-primary"></i> Интеграции с CRM</li>
                                    <li className="mb-2"><i className="fas fa-check text-primary"></i> Интеграции с системами заказчика</li>
                                    <li className="mb-2"><i className="fas fa-check text-primary"></i> Отчеты индивидуального формата</li>
                                    <li className="mb-2"><i className="fas fa-check text-primary"></i> Отчеты в PDF/Word</li>
                                    <li className="mb-2"><i className="fas fa-check text-primary"></i> Импорт нормативной базы заказчика</li>
                                    <li className="mb-2"><i className="fas fa-check-double text-primary"></i> Поддержка внедрения с ежемесячными обновлениями и доработками </li>
                                </>
                            ):(
                                <>
                                    <li className="mb-2">
                                        <i className="bi bi-check text-primary"></i>
                                        <strong>{ inclineWord(limit_users, 'пользователь', true) }</strong>
                                    </li>
                                    <li className="mb-2">
                                        <i className="bi bi-check text-primary"></i>
                                        <strong>{ inclineWord(limit_forms_per_month, 'приёмка / месяц', true) }</strong>
                                    </li>
                                    <li className="mb-2"><i className="fas fa-check text-primary"></i> Настройка стуктуры приёмки</li>
                                    <li className="mb-2"><i className="fas fa-check text-primary"></i> Настройка нормативной базы</li>
                                    <li className={!!+feature_comments ? "mb-2" : "mb-2 text-muted"}>
                                        <i className={!!+feature_comments ? "fas fa-check text-primary" : "fas fa-xmark"}></i> Комментарии к недостаткам
                                    </li>
                                    <li className={!!+feature_photo ? "mb-2" : "mb-2 text-muted"}>
                                        <i className={!!+feature_photo ? "fas fa-check text-primary" : "fas fa-xmark"}></i> Фото в приёмках
                                    </li>
                                    <li className={!!+feature_offline ? "mb-2" : "mb-2 text-muted"}>
                                        <i className={!!+feature_offline ? "fas fa-check text-primary" : "fas fa-xmark"}></i> Оффлайн режим
                                    </li>
                                    <li className="mb-2">
                                        {
                                            !!+priority_support ? (
                                                <>
                                                    <i className="fas fa-check-double text-primary"></i> <strong>Приоритетная</strong> поддержка от разработчика, время реакции 1 час
                                                </>
                                            ):(
                                                <>
                                                    <i className="fas fa-check text-primary"></i> Стандартная поддержка от разработчика
                                                </>
                                            )
                                        }
                                        
                                    </li>
                                </>
                            )
                        }
                    </ul>
                    {
                        activePlan && activePlan.id==id ? (
                            isFutureDateTime(activePlan?.date_plan_end) ? (
                                <div className="d-grid">
                                    <Button
                                        disabled={true}
                                        variant='success'
                                    >
                                        Оплачен еще {activePlan.days_left} дн
                                    </Button>
                                </div>
                            ):(
                                <div className="d-grid">
                                    <Button
                                        onClick={ ()=> {
                                            if (window.confirm('Перейти к оплате?')) {
                                                createPayment({planid: id})
                                            }
                                        }}
                                    >
                                        Оплатить 30 дней
                                    </Button>
                                </div>
                            )
                        ):(
                                !!+is_monthly ? (
                                    <div className="d-grid">
                                        <Button 
                                            className="btn btn-outline-primary text-white" 
                                            onClick={()=>{
                                                if (window.confirm(`После оплаты будет активирован тариф "${name}".\n\nПерейти к оплате?`)) {
                                                    createPayment({planid: id})
                                                }
                                            }}
                                        >
                                            Перейти на тариф и оплатить
                                        </Button>
                                        <Button
                                            onClick={toggleShowHelp}
                                            className='mb-3 px-5 text-decoration-none'
                                            variant='link'
                                        >
                                            Оплата по счету
                                        </Button>
                                    </div>
                                ) : (
                                    <div className="d-grid">
                                        <Button
                                            onClick={toggleShowGetOffer}
                                            className='px-5 text-decoration-none'
                                            variant='outline-primary'
                                        >
                                            💪 Получить предложение
                                        </Button>
                                    </div>

                                )
                        )
                    }
                </div>
            </div>
        )
    }
    
    const CreditCard = (paycard_json) => {
        if ( paycard_json ){
            const paycard = JSON.parse(paycard_json)
            const { card_product: {name}, card_type, first6, last4, expiry_month, expiry_year } = paycard
            return (
                    <Modal 
                        show={showCard} 
                        onHide={()=>setShowCard(false)} 
                        animation={false} 
                        centered
                    >
                        <Modal.Header closeButton>
                            <Modal.Title>{ name }</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <FloatingLabel label={card_type} className='mb-3'>
                                <Form.Control 
                                    id="name"
                                    type="text" 
                                    value={`${first6}** **** ${last4}`} 
                                    readOnly={true}
                                    style={{fontSize:22}}
                                />
                            </FloatingLabel>
                            <FloatingLabel label="Срок действия" className='mb-3 w-50'>
                                <Form.Control 
                                    id="name"
                                    type="text" 
                                    value={`${expiry_month}/${expiry_year}`}
                                    readOnly={true}
                                />
                            </FloatingLabel>
                        </Modal.Body>
                    </Modal>
            )
        }
    }
  
    return(
        <>
            <h1>Тарифный план</h1>

            {
                !activePlan.id ? (
                    <Alert variant="warning" className='mb-5' style={{maxWidth:800}}>
                        <Alert.Heading>👋 Это демо-режим</Alert.Heading>
                        Доступ предоставлен в ознакомительных целях. Часть функций ограничена.<br/>
                        Подключите один из тарифов для полного доступа.
                    </Alert>
                ):null
            }

            <div className="container mx-0">
                <div className="row my-4">
                    <div className="col-lg-4">
                        {
                            !!plans.length ? (
                                plans.filter(({id})=>(id==activePlan.id)).map( plan => PlanCard( plan ))
                            ) : null
                        }
                    </div>
                    <div className="col-lg-8">
                        
                        {
                            billingTransactions()
                        }
                    </div>
                </div>
                <div className="row">
                    <div className='h2 my-3'>Все тарифы</div>
                        {
                            !!plans.length ? (
                                plans.filter(({id})=>(id!=activePlan.id)).map( plan => (
                                    <div className="col-md-6 col-xl-4">
                                        {PlanCard( plan )}
                                    </div>
                                ))
                            ) : null
                        }
                </div>
            </div>

            {
                CreditCard( activePlan?.paycard )
            }           
            

            <Modal 
                show={showHelp}
                onHide={toggleShowHelp}
                backdrop="static"
                keyboard={false}
                size="md"
                centered
            >
                <Modal.Header closeButton  className='bg-light'>
                    <Modal.Title>Оплата с юр. лица?</Modal.Title>
                </Modal.Header>
                <Modal.Body className='bg-light'>
                    Без проблем. Перейдите в чат поддержки и запросите выставление счета, указав ИНН и наименование организации.
                </Modal.Body>
                <Modal.Footer className='bg-light'>
                    <Button variant="secondary-outline" onClick={toggleShowHelp}>Отмена</Button>
                    <a href="https://t.me/stumarkin" target="_blank" className='btn btn-primary'>Запросить счет через Telegram</a>
                </Modal.Footer>
            </Modal>



            <Modal 
                show={showGetOffer}
                onHide={toggleShowGetOffer}
                backdrop="static"
                keyboard={false}
                size="lg"
                centered
            >
                <Modal.Header closeButton  className='bg-light'>
                    <Modal.Title>Необходим индивидуальный подход?</Modal.Title>
                </Modal.Header>
                <Modal.Body className='bg-light'>
                    Основатель сервиса раскажет о системе и возможностях доработок под ваши требования. Запросите консультацию в чате поддержки.
                </Modal.Body>
                <Modal.Footer className='bg-light'>
                    <Button variant="secondary-outline" onClick={toggleShowHelp}>Отмена</Button>
                    <a href="https://t.me/stumarkin" target="_blank" className='btn btn-primary'>Запросить счет через Telegram</a>
                </Modal.Footer>
            </Modal>


        </>

    )
}