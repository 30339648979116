/**
 * @author Sergey Tumarkin https://t.me/stumarkin
 */
import { 
    Alert,
    FloatingLabel,
    Form,
    Button,
    Container,
    Row,
    Col,
    Card
 } from 'react-bootstrap';
import * as API from '../../data/API';
import { useState, useEffect, useCallback, useRef } from 'react';


export default function YandexDisk ( props ){
    const {
        authtoken,
        account
    } = props

    const [isLoading, setIsLoading] = useState(false)

    const isYandexDiskFeatureAvaliable = !!+account?.plan?.feature_yandex_disk
    const [isYandexDiskChanged, setIsYandexDiskChanged] = useState(false)
    const [postYandexDiskSuccess, setPostYandexDiskSuccess] = useState(false)

    const [yandexDiskCredentials, setYandexDiskCredentials] = useState({})
    const [yandexDiskCredentialsIsActive, setYandexDiskCredentialsIsActive] = useState(0)
    const [yandexDiskCredentialsEndpoint, setYandexDiskCredentialsEndpoint] = useState('https://cloud-api.yandex.net/v1/')
    const [yandexDiskCredentialsToken, setYandexDiskCredentialsToken] = useState('')
    
    useEffect(() => {
        GetYandexDiskCredentials()
    }, [])

    useEffect(() => {
        const isYandexDiskFormChanged = (
            yandexDiskCredentialsIsActive != yandexDiskCredentials.is_active 
            || yandexDiskCredentialsEndpoint != yandexDiskCredentials.endpoint 
            || yandexDiskCredentialsToken != yandexDiskCredentials.token 
            
        )
        setIsYandexDiskChanged( isYandexDiskFormChanged )
        setPostYandexDiskSuccess( postYandexDiskSuccess && !isYandexDiskFormChanged )
    }, [
        yandexDiskCredentialsIsActive, 
        yandexDiskCredentialsEndpoint, 
        yandexDiskCredentialsToken
    ])

    const GetYandexDiskCredentials = () => {
        setIsLoading(true)
        API.Get({ method:'getyandexdiskcredentials', authtoken})
        .then(res => {
            const {result, yandexdiskcredentials} = res.data
            if ( result && yandexdiskcredentials ){
                const {is_active, endpoint, token} = yandexdiskcredentials
                setYandexDiskCredentials( yandexdiskcredentials )
                setYandexDiskCredentialsIsActive( is_active )
                setYandexDiskCredentialsEndpoint( endpoint )
                setYandexDiskCredentialsToken( token )
            }
        })
        .finally(()=>setIsLoading(false))
    }

    const TestYandexDiskConnection = () => {
        setIsLoading(true)
        API.Get({ method:'testyandexdiskconnection', authtoken, endpoint: yandexDiskCredentialsEndpoint, token: yandexDiskCredentialsToken })
        .then(res => {
            const {result, http_code, response} = res.data
            const header = (result ? 'Успешно' : 'Ошибка')
            alert( `${header}\n\nhttp_code = ${http_code} \n\nresponse = ${response}`)
        })
        .finally(()=>setIsLoading(false))
    }

    const PostYandexDiskCredentials = () => {
        setIsLoading(true)
        API.Post({ method:'postyandexdiskcredentials', authtoken}, {
            is_active: yandexDiskCredentialsIsActive,
            endpoint: yandexDiskCredentialsEndpoint,
            token: yandexDiskCredentialsToken,
        })
        .then(res => {
            console.log( res.data )
            if (res.data.result){
                setPostYandexDiskSuccess( true )
                setIsYandexDiskChanged( false )
            }
        })
        .finally(()=>setIsLoading(false))
    }
    
    return (
        <>
            <Container className='ms-0 ps-0'>
                <Row>
                    <Col>
                        <h1 className='mb-4'>Интеграция с Яндекс.Диск</h1>
                        {
                            !isYandexDiskFeatureAvaliable && 
                            <Alert variant="warning" className='mb-3'>
                                Функция доступна для тарифов Бизнес и выше.
                            </Alert>
                        }
                        
                        <Card>
                            <Card.Header>
                                <Form.Check // prettier-ignore
                                    type="switch"
                                    id="custom-switch"
                                    label={`Интеграция с Яндекс.Диск ${(yandexDiskCredentialsIsActive ? 'подключена' : 'отключена')}`}
                                    value={true} 
                                    checked={!!+yandexDiskCredentialsIsActive && isYandexDiskFeatureAvaliable}
                                    isValid={!!+yandexDiskCredentialsIsActive && isYandexDiskFeatureAvaliable}
                                    onChange={(e)=> setYandexDiskCredentialsIsActive(+e.target.checked) }
                                    disabled={!isYandexDiskFeatureAvaliable}
                                />
                            </Card.Header>
                            <Card.Body>
                                <Card.Text>
                                    <FloatingLabel label="URL подключения" className='mb-3'>
                                        <Form.Control 
                                            id="endpoint"
                                            type="text" 
                                            placeholder=''
                                            value={yandexDiskCredentialsEndpoint} 
                                            onChange={(e)=> setYandexDiskCredentialsEndpoint(e.target.value) }
                                            disabled={true}
                                            />
                                    </FloatingLabel>
                                    <FloatingLabel label="OAuth-токен" className='mb-4'>
                                        <Form.Control 
                                            id="token" 
                                            type="text"
                                            placeholder=''
                                            value={yandexDiskCredentialsToken} 
                                            onChange={(e)=> setYandexDiskCredentialsToken(e.target.value) }
                                            disabled={!+yandexDiskCredentialsIsActive || !isYandexDiskFeatureAvaliable}
                                        />
                                    </FloatingLabel>
                                    <div className="row">
                                        <div className="col col-12 col-md-6">
                                            <Button
                                                variant="outline-primary"
                                                className='w-50'
                                                disabled={!+yandexDiskCredentialsIsActive || !isYandexDiskFeatureAvaliable}
                                                onClick={ TestYandexDiskConnection }
                                                size="sm"
                                            >
                                                Проверить подключение
                                            </Button>
                                        </div>
                                    </div>
                                </Card.Text>
                            </Card.Body>
                        </Card>

                        <div className="row py-3">
                            <div className="col col-12 col-md-6">
                                <Button
                                    variant={ postYandexDiskSuccess ? 'success' : 'primary' }
                                    className='w-100 mb-3 px-5 py-3'
                                    disabled={!isYandexDiskChanged || !isYandexDiskFeatureAvaliable}
                                    onClick={ PostYandexDiskCredentials }
                                >
                                    { postYandexDiskSuccess ? 'Сохранено' : 'Сохранить изменения' }
                                </Button>
                            </div>
                        </div>
                        
                    </Col>
                </Row>
            </Container>
        </>
    )
}
