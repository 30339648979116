/**
 * @author Sergey Tumarkin https://t.me/stumarkin
 */
import { 
    Accordion,
    Alert,
    FloatingLabel,
    Form,
    Button,
    Container,
    Row,
    Col,
    Card,
    InputGroup
 } from 'react-bootstrap';
import * as API from '../../data/API';
import { useState, useEffect, useCallback, useRef } from 'react';


export default function Calculator ( props ){
    const {
        authtoken,
        account
    } = props

    const [isLoading, setIsLoading] = useState(false)
    const [isHelpVisible, setIsHelpVisible] = useState(false)
    const [formButtonStatus, setFormButtonStatus] = useState('default') // 'default' || 'isloading' || 'success' 
    

    const [counter, setCounter] = useState(0)
    const [calculatorConfig, setCalculatorConfig] = useState({})
    const [calculatorIsActive, setCalculatorIsActive] = useState(false)
    
    const reestrUrl = 'https://priemka-pro.ru/reestr/';
    const defaultCalculatorConfig = {
        'isActive': false,
        'finishTypes':[
            { name: 'Черновая', pricePerMeter: 70 },
            { name: 'Предчистовая', pricePerMeter: 80 },
            { name: 'Чистовая', pricePerMeter: 90 }
        ],
        'rooms': [
            { name: 'Студия', coefficient: 1, 'readonly': true },
            { name: '1', coefficient: 1 },
            { name: '2', coefficient: 1.1 },
            { name: '3', coefficient: 1.1 },
            { name: '4', coefficient: 1.2 },
        ],
        'additionalServices': [
            { name: 'Пакет НОПРИЗ', price: 4000 },
            { name: 'Тепловизор', price: 3500 },
            { name: 'Обмер и чертеж', price: 4000 },
            { name: 'Оценка для банка', price: 3800 },
            { name: 'Замеры излучений', price: 0 }
        ],
        'basePrices': {
            'minimumPrice': 3500,
            'areaLimitForMinimumPrice': 30
        },
        'selectedFinishType': 0,
        'selectedRooms': 0,
        'selectedServices': [4],
        'area': 30,
        'form': {
            'complexName': '',
            'clientName': '',
            'phone': ''
        }
    }
    const statuses = {
        'draft': {
            'header':'Ожидает модерации',
            'color':'warning',
            'bottom_comment': 'Максимальный срок проверки – 3 рабочих дня, но обычно быстрее. Вы можете свободно вносить изменения, проверена будет последняя сохраненная версия.'
        },
        'rejected': {
            'header':'Отклонено, требует корректировки',
            'color':'danger',
            'bottom_comment': 'Измените контент лендинг в соответствии с комментарием модератора и сохраните для отправки на повторную модерацию.'
        },
        'published': {
            'header':'Опубликовано',
            'color':'success',
            'bottom_comment': 'Измените контент лендинг в соответствии с комментарием модератора и сохраните для отправки на повторную модерацию.'
        }
    }
    const cities = [
        { name: "Москва", code: "moscow" },
        { name: "Санкт-Петербург", code: "spb" },
        { name: "Новосибирск", code: "novosib" },
        { name: "Екатеринбург", code: "ekat" },
        { name: "Казань", code: "kazan" },
        { name: "Нижний Новгород", code: "nn" },
        { name: "Челябинск", code: "chelyabinsk" },
        { name: "Самара", code: "samara" },
        { name: "Омск", code: "omsk" },
        { name: "Ростов-на-Дону", code: "rostov-on-don" },
        { name: "Уфа", code: "ufa" },
        { name: "Красноярск", code: "krasnoyarsk" },
        { name: "Пермь", code: "perm" },
        { name: "Воронеж", code: "voronezh" },
        { name: "Волгоград", code: "volgograd" },
        { name: "Краснодар", code: "krasnodar" },
        { name: "Саратов", code: "saratov" },
        { name: "Тюмень", code: "tyumen" },
        { name: "Тольятти", code: "tolyatti" },
        { name: "Ижевск", code: "izhevsk" },
        { name: "Барнаул", code: "barnaul" },
        { name: "Ульяновск", code: "ulyanovsk" },
        { name: "Иркутск", code: "irkutsk" },
        { name: "Хабаровск", code: "khabarovsk" },
        { name: "Ярославль", code: "yaroslavl" },
        { name: "Владивосток", code: "vladivostok" },
        { name: "Махачкала", code: "makhachkala" },
        { name: "Томск", code: "tomsk" },
        { name: "Оренбург", code: "orenburg" },
        { name: "Кемерово", code: "kemerovo" },
      ];

    const [landingContent, setLandingContent] = useState(null);
    const [code, setCode] = useState(reestrUrl);
    const [name, setName] = useState('')
    const [city, setCity] = useState('')
    const [logo, setLogo] = useState('')
    const [description, setDescription] = useState('')
    const [requisites, setRequisites] = useState('')
    const [reviewsUrl, setReviewsUrl] = useState('')

    const [finishTypes, setFinishTypes] = useState([])
    const [rooms, setRooms] = useState([])
    const [additionalServices, setAdditionalServices] = useState([])
    const [minimumPrice, setMinimumPrice] = useState(0)


    
    useEffect(
        () => {
            GetLandingContent()
        }, 
        []
    )

    useEffect(
        () => {
            setFinishTypes( calculatorConfig.finishTypes )
            setRooms( calculatorConfig.rooms )
            setAdditionalServices( calculatorConfig.additionalServices )
            setMinimumPrice( calculatorConfig.basePrices?.minimumPrice )
        }, 
        [ calculatorConfig ]
    )


    const inputHandler = ( setter ) => {
        if (setter && typeof setter === "function") {
            setter();
        }
        setCounter(prev => prev + 1)
        setFormButtonStatus('default')
      };

    const GetLandingContent = () => {
        setIsLoading(true)
        API.Get({ method:'getlandingcontent', authtoken})
        .then(({data}) => {
            // console.log(data);
            const {result, landingcontent} = data
            if ( result && landingcontent ){
                setLandingContent(landingcontent)
                const {
                    code, 
                    name,
                    city,
                    logo,
                    description,
                    requisites,
                    reviews_url,
                    calculator_config_json,
                }  = landingcontent
                setCode( reestrUrl + code )
                setName( name )
                setCity( city )
                setLogo( logo )
                setDescription( escapeBR(description) )
                setRequisites( escapeBR(requisites) )
                setReviewsUrl( reviews_url )
                const calculatorConfig = JSON.parse(calculator_config_json)
                setCalculatorConfig(calculatorConfig) 
                setCalculatorIsActive(calculatorConfig.isActive)
            } else {
                API.Get({ method:'getaccount', authtoken})
                .then(({data}) => {
                    const {id, name, requisites, logo} = data.account
                    setRequisites( requisites )
                    setName( name )
                    setLogo( logo )
                    setCode( reestrUrl + transliterate(name).toLowerCase() )
                })
                setCalculatorConfig ( defaultCalculatorConfig ) 
            }
        })
        .finally(()=>{
            setIsLoading(false)
        })
    }

    const PostLandingContent = () => {
        setFormButtonStatus('isloading')
        API.Post({ method:'postlandingcontent', authtoken}, {
            code: code.split('/').pop().toLowerCase(),
            name,
            city,
            logo,
            description: escapeN(description),
            requisites: escapeN(requisites),
            min_price: minimumPrice,
            reviews_url: reviewsUrl,
            calculator_config_json: JSON.stringify(
                {
                    ...calculatorConfig, 
                    isActive: calculatorIsActive,
                    finishTypes, 
                    rooms: rooms.map( room => ({...room, coefficient: parseFloat(room.coefficient)})), // str to float
                    additionalServices: additionalServices.filter(({name}) => name.trim()!=''), 
                    basePrices: {
                        minimumPrice, 
                        areaLimitForMinimumPrice: 30
                    }
                }
            )
        })
        .then(({data}) => {
            console.log( data )
            GetLandingContent()
            setFormButtonStatus('success')
        })
        .catch(()=>setFormButtonStatus('default'))
    }

    function isNumericWithDot(str) {
        return str === "" || /^[0-9]+(\.[0-9]*)?$/.test(str);
    }

    const isValidUrlPath = (directory) => /^[a-zA-Z0-9-\.]+$/.test(directory);

    const escapeN = (str) => {
        return str.replace( /\n/g, "<br>")
    }
    
    const escapeBR = (str) => {
        return str.replace( /<br>/g, "\n")
    }

    function transliterate(text) {
        const map = {
            'А':'A','Б':'B','В':'V','Г':'G','Д':'D','Е':'E','Ё':'Yo','Ж':'Zh','З':'Z','И':'I','Й':'Y','К':'K','Л':'L','М':'M',
            'Н':'N','О':'O','П':'P','Р':'R','С':'S','Т':'T','У':'U','Ф':'F','Х':'Kh','Ц':'Ts','Ч':'Ch','Ш':'Sh','Щ':'Shch',
            'Ъ':'','Ы':'Y','Ь':'','Э':'E','Ю':'Yu','Я':'Ya','а':'a','б':'b','в':'v','г':'g','д':'d','е':'e','ё':'yo','ж':'zh',
            'з':'z','и':'i','й':'y','к':'k','л':'l','м':'m','н':'n','о':'o','п':'p','р':'r','с':'s','т':'t','у':'u','ф':'f',
            'х':'kh','ц':'ts','ч':'ch','ш':'sh','щ':'shch','ъ':'','ы':'y','ь':'','э':'e','ю':'yu','я':'ya'
        };
        return text.replace(/[^А-Яа-яЁё]/g, '').replace(/[А-Яа-яЁё]/g, char => map[char] || '');
    }

    const HelpAccordion = () => (
        <Accordion className='mb-4'>
            <Accordion.Item eventKey="0">
                <Accordion.Header>Что такое лендинг и зачем он нужен?</Accordion.Header>
                <Accordion.Body>
                    Лендинг – это ваша персональная веб-страница в интернете, ваш одностраничный сайт. На этой странице вы можете опубликовать описание вашей компании, вашей преимущества, реквизиты вашего юрлица, отзывы клиентов о вашей деятельности.<br/><br/>
                    Вы также можете настроить и размесить на лендинге форму расчёта стоимости ваших услуг приёмки с возможностью оформления заказа. Заявки на приёмку поступают в панель упраления и мобильное приложение Приемка Про. 
                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
                <Accordion.Header>Где будет располагаться лендинг</Accordion.Header>
                <Accordion.Body>
                    Лендинги всех компаний располагаются на сайте Приёмка Про в разделе <a href="https://priemka-pro.ru/reestr/" target='_blank'>реестр компаний приёмки</a>. 
                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="2">
                <Accordion.Header>Можно ли подключить свой домен?</Accordion.Header>
                <Accordion.Body>
                    Да, есть возможность подключить свой домен. Если вы уже приобрели домен ранее, то напишите в чат поддержки для дальнейших инструкций по настройке. Если домен не приобретался, то он может быть заказан в рамках любого тарифа в подписке Приемка Про.
                </Accordion.Body>
            </Accordion.Item>
        </Accordion>
    )

    return (
        <>
            <h1 className='mb-4'>
                Настройки лендинга 
                <Button 
                    variant="outline-secondary" 
                    size="sm"
                    className='ms-2'
                    onClick={()=>setIsHelpVisible(prev=>!prev)}
                >
                    Справка
                </Button>
            </h1>
                   
            {
                isHelpVisible && HelpAccordion()
            }
            
            {
            landingContent?.status && 
            <Alert variant={ statuses[landingContent?.status].color }>
                <Alert.Heading>{statuses[landingContent?.status].header}</Alert.Heading>
                {
                    landingContent?.status=='rejected' && landingContent?.moderation_comment &&
                    <>
                        <p>{landingContent?.moderation_comment}</p>
                        <hr />
                    </>
                }
                {
                    landingContent?.status!='published' && statuses[landingContent?.status].bottom_comment &&
                    <>
                        <p className="mb-0">{statuses[landingContent?.status].bottom_comment}</p>
                    </>
                }
            </Alert>
            }

            <FloatingLabel label="Адрес лендинга" className='mb-3'>
                <Form.Control
                    id="code"
                    type="text"
                    placeholder=''
                    value={code}
                    onChange={ (e) => {
                        const value = e.target.value;
                        if (value.indexOf(reestrUrl) != 0) { return }
                        const lastDirectory = value.split('/').pop().toLowerCase();
                        if (isValidUrlPath(lastDirectory) || lastDirectory === '') {
                            inputHandler( ()=>setCode(value.toLowerCase()) )
                        }
                    }}
                />
            </FloatingLabel>
            
            <FloatingLabel label="Наименование компании/бренда" className='mb-3'>
                <Form.Control 
                    id="name"
                    type="text" 
                    placeholder=''
                    value={name} 
                    onChange={(e)=> setName(e.target.value) }
                    />
            </FloatingLabel>

            <FloatingLabel controlId="floatingSelect" label="Выберите город" className='mb-4'>
                <Form.Select 
                    aria-label="Выбор города"
                    value={city}
                    onChange={ e => inputHandler( ()=>setCity(e.target.value) ) }
                >
                    <option>Выберите город</option>
                    { 
                        cities.map((city) => <option key={city.code} value={city.code}>{city.name}</option>)
                    }
                </Form.Select>
            </FloatingLabel>

            <FloatingLabel label="Описание услуг" className='mb-4'>
                <Form.Control 
                    id="description" 
                    as="textarea"
                    placeholder=''
                    rows={3}
                    value={description} 
                    onChange={(e)=> inputHandler( ()=>setDescription(e.target.value) ) }
                    style={{height: 150}}
                />
            </FloatingLabel>

            <FloatingLabel label="Реквизиты" className='mb-4'>
                <Form.Control 
                    id="requisites" 
                    as="textarea"
                    placeholder=''
                    rows={3}
                    value={requisites} 
                    onChange={(e)=> inputHandler( ()=>setRequisites(e.target.value) ) }
                    style={{height: 150}}
                />
            </FloatingLabel>

            <FloatingLabel label="Ссылка на отзывы" className='mb-4'>
                <Form.Control 
                    id="setReviewsUrl" 
                    as="textarea"
                    placeholder=''
                    rows={3}
                    value={reviewsUrl} 
                    onChange={(e)=> inputHandler( ()=>setReviewsUrl(e.target.value) ) }
                />
                <Form.Text id="setReviewsUrlComment" muted>Если о вас уже оставляли отзывы на другом ресурсе, то укажите оставьте ссылку для использования этих данных для рейтинга на лендинге</Form.Text>
            </FloatingLabel>

            <Card>
                <Card.Header>
                    <Form.Check // prettier-ignore
                        type="switch"
                        id="custom-switch"
                        label={`Расчет цены и приём заявок ${(calculatorIsActive ? 'подключен' : 'отключен')}`}
                        value={true} 
                        checked={ calculatorIsActive }
                        isValid={ calculatorIsActive }
                        onChange={(e)=> inputHandler( ()=>setCalculatorIsActive(+e.target.checked) ) }
                    />
                </Card.Header>
                <Card.Body>
                    <Card.Title as="h5">
                        Калькулятор приёмки
                    </Card.Title>
                    <Card.Text>
                        <Container className='ms-0 ps-0'>
                            <Row>
                                <Col>
                                    <h6 className="mt-4">Базовая стоимость за м<sup>2</sup> в зависимости от типа отделки:</h6>
                                    {
                                        finishTypes?.map( (finishType, i) => (
                                            <Row className="g-2">
                                                <Col md={4}>
                                                    <InputGroup className="mb-3">
                                                        <InputGroup.Text>{finishType.name}</InputGroup.Text>
                                                        <Form.Control 
                                                            aria-label="00" 
                                                            className='text-end'
                                                            value={ finishType.pricePerMeter }
                                                            onChange={ e => {
                                                                    if (isNumericWithDot(e.target.value)){
                                                                        finishTypes[i].pricePerMeter = +e.target.value
                                                                        inputHandler( ()=>setFinishTypes( finishTypes ) )
                                                                    }
                                                                }
                                                            }
                                                        />
                                                        <InputGroup.Text>₽/м<sup>2</sup></InputGroup.Text>
                                                    </InputGroup>
                                                </Col>
                                            </Row>
                                        ))
                                    }
                                    
                                    <h6 className="mt-4">Коэфициент наценки/скидки в зависимости от кол-ва комнат:</h6>
                                    <div className='small mb-3'>
                                        Базовая стоимость за м<sup>2</sup> применяется к студиям.<br/>
                                        К остальным размерам квартир можно применить коэфициент для измемения цены.<br/>
                                        Кофициент ниже единицы – это скидка (напр., 0.9 - это скидка 10%)<br/>
                                        Кофициент выше единицы – это наценка (напр., 1.15 - это наценка 15%)
                                    </div>
                                    
                                    <Row className="g-2">
                                        {
                                            rooms?.map( (room, i) => {
                                                if (!room.readonly) return (
                                                    <Col md={2} className="pe-4">
                                                        <InputGroup>
                                                            <InputGroup.Text>{room.name}кк</InputGroup.Text>
                                                            <Form.Control 
                                                                aria-label="00" 
                                                                className='text-end'
                                                                value={ room.coefficient }
                                                                onChange={ e => {
                                                                        if (isNumericWithDot(e.target.value)){
                                                                            rooms[i].coefficient = e.target.value
                                                                            inputHandler( ()=>setRooms( rooms ) )
                                                                        }
                                                                    }
                                                                }
                                                            />
                                                        </InputGroup>
                                                    </Col>
                                                )
                                            })
                                        }
                                    </Row>

                                    <h6 className="mt-5">Минимальная стоимость приёмки:</h6>
                                    <div className='small mb-3'>
                                        Применяется, если расчет по площади и выбранным доп. услугам ниже указанного значения.
                                    </div>
                                    <Row className="g-2">
                                        <Col md={2}>
                                            <InputGroup className="mb-3">
                                                
                                                <Form.Control 
                                                    className='text-end'
                                                    value={minimumPrice}
                                                    onChange={ (e) => {
                                                        if (isNumericWithDot(e.target.value)){
                                                            inputHandler( ()=>setMinimumPrice( +e.target.value ))
                                                        } }
                                                        }
                                                />
                                                <InputGroup.Text>₽</InputGroup.Text>
                                            </InputGroup>
                                        </Col>
                                    </Row>


                                    <h6 className="mt-5">Дополнительные услуги с фиксированной стоимостью:</h6>
                                    <div className='small mb-3'>
                                        Укажите стоимость равную 0, если предоставляете услугу в подарок.<br/>Клиенты любят подарки, вероятность заказа услуги повысится.
                                    </div>
                                    <div>
                                        {
                                            additionalServices?.map( (additionalService, i) => (
                                                <Row className="g-2">
                                                    <Col md={6}>
                                                        <InputGroup className="mb-3">
                                                            <Form.Control 
                                                                className='w-50'
                                                                value={ additionalService.name }
                                                                onChange={ e => {
                                                                        additionalServices[i].name = e.target.value
                                                                        inputHandler( ()=>setAdditionalServices( additionalServices ) )
                                                                    }
                                                                }
                                                            />
                                                            <Form.Control 
                                                                aria-label="00" 
                                                                className='text-end'
                                                                value={ additionalService.price }
                                                                onChange={ e => {
                                                                        if (isNumericWithDot(e.target.value)){
                                                                            additionalServices[i].price = +e.target.value
                                                                            inputHandler( ()=>setAdditionalServices( additionalServices ) )
                                                                        }
                                                                    }
                                                                }
                                                            />
                                                            <InputGroup.Text>₽</InputGroup.Text>
                                                            <Button 
                                                                className='text-danger text-decoration-none'
                                                                variant="link" 
                                                                size="sm"
                                                                onClick={()=>{
                                                                    additionalServices.splice(i, 1);
                                                                    inputHandler( ()=>setAdditionalServices( additionalServices ) )
                                                                }}
                                                            >
                                                                🗑
                                                            </Button>
                                                        </InputGroup>
                                                    </Col>
                                                </Row>
                                            ))
                                        }

                                        <Button 
                                            variant="outline-primary" 
                                            size="sm"
                                            onClick={()=>{
                                                additionalServices.push({name:'', price:0})
                                                inputHandler( ()=>setAdditionalServices( additionalServices ) )
                                            }}
                                        >
                                            Добавить
                                        </Button>
                                    </div>
                                    
                                </Col>
                            </Row>
                        </Container>
                    </Card.Text>
                </Card.Body>
            </Card>
            
            <div className="row py-3">
                <div className="col col-12 col-md-6">
                    <Button
                        variant={ formButtonStatus=='success' ? 'success' : 'primary' }
                        className='w-100 mb-3 px-5 py-3'
                        onClick={ ()=>{
                            PostLandingContent()
                        } }
                        disabled={ ['isloading','success'].includes(formButtonStatus) }
                    >
                        { formButtonStatus=='success' ? 'Сохранено' : 'Сохранить изменения' }
                    </Button>
                </div>
            </div>
        </>
    )
}
