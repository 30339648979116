/**
 * @author Sergey Tumarkin https://t.me/stumarkin
 */
import { 
    Alert,
    FloatingLabel,
    CloseButton, 
    Form,
    Button,
    Card,
    Image,
    Container,
    Row,
    Col,
    Tab,
    Tabs,
    Nav
 } from 'react-bootstrap';
import * as API from '../data/API';
import { useState, useEffect, useCallback, useRef } from 'react';
import YandexDisk from './Connections/YandexDisk';
import CostCalculator from './Connections/Landing';
import SortableList, { SortableItem } from "react-easy-sort";
import arrayMove from "array-move";
import * as Config from '../data/Config';


export default function SettingsСonnections ( props ){
    const {
        authtoken,
        account
    } = props

    const [isLoading, setIsLoading] = useState(false)

    // AmoCRM
    const isAmoFeatureAvaliable = !!+account?.plan?.feature_amocrm || false
    const [isAmoChanged, setIsAmoChanged] = useState(false)
    const [postAmoSuccess, setPostAmoSuccess] = useState(false)

    const [amoCredentials, setAmoCredentials] = useState({})
    const [amoCredentialsIsActive, setAmoCredentialsIsActive] = useState(0)
    const [amoCredentialsEndpoint, setAmoCredentialsEndpoint] = useState('')
    const [amoCredentialsToken, setAmoCredentialsToken] = useState('')
    
    const [amoCredentialsDateFieldId, setAmoCredentialsDateFieldId] = useState('')
    const [amoCredentialsGetaddressFieldId, setAmoCredentialsGetaddressFieldId] = useState('')
    
    const [amoCredentialsSetaddressFieldId, setAmoCredentialsSetaddressFieldId] = useState('')
    const [amoCredentialsReportFieldId, setAmoCredentialsReportFieldId] = useState('')
    const [amoCredentialsReportExpertFieldId, setAmoCredentialsReportExpertFieldId] = useState('')
    
    useEffect(() => {
        const isAmoFormChanged = (
            amoCredentialsIsActive != amoCredentials.is_active 
            || amoCredentialsEndpoint != amoCredentials.endpoint 
            || amoCredentialsToken != amoCredentials.token 
            || amoCredentialsDateFieldId != amoCredentials.date_field_id 
            || amoCredentialsGetaddressFieldId != amoCredentials.getaddress_field_id 
            || amoCredentialsSetaddressFieldId != amoCredentials.setaddress_field_id 
            || amoCredentialsReportFieldId != amoCredentials.report_field_id 
            || amoCredentialsReportExpertFieldId != amoCredentials.report_expert_field_id 
        )
        setIsAmoChanged( isAmoFormChanged )
        setPostAmoSuccess( postAmoSuccess && !isAmoFormChanged )
    }, [
        amoCredentialsIsActive, 
        amoCredentialsEndpoint, 
        amoCredentialsToken, 
        amoCredentialsDateFieldId, 
        amoCredentialsGetaddressFieldId, 
        amoCredentialsSetaddressFieldId, 
        amoCredentialsReportFieldId, 
        amoCredentialsReportExpertFieldId 
    ])

    useEffect(() => {
        GetAmoCredentials()
    }, [])

    const GetAmoCredentials = () => {
        setIsLoading(true)
        API.Get({ method:'getamocredentials', authtoken})
        .then(res => {
            const {result, amocredentials} = res.data
            if ( result && amocredentials ){
                const {is_active, endpoint, token, date_field_id, getaddress_field_id, setaddress_field_id, report_field_id, report_expert_field_id} = amocredentials
                setAmoCredentials( amocredentials )
                setAmoCredentialsIsActive( is_active )
                setAmoCredentialsEndpoint( endpoint )
                setAmoCredentialsToken( token )
                setAmoCredentialsDateFieldId( date_field_id )
                setAmoCredentialsGetaddressFieldId( getaddress_field_id )
                setAmoCredentialsSetaddressFieldId( setaddress_field_id )
                setAmoCredentialsReportFieldId( report_field_id )
                setAmoCredentialsReportExpertFieldId( report_expert_field_id )
            }
        })
        .finally(()=>setIsLoading(false))
    }

    const TestAmoConnection = () => {
        setIsLoading(true)
        API.Get({ method:'testamoconnection', authtoken, endpoint: amoCredentialsEndpoint, token: amoCredentialsToken })
        .then(res => {
            console.log(res.data);
            const {result, http_code, response} = res.data
            const header = (result ? 'Успешно' : 'Ошибка')
            alert( `${header}\n\nhttp_code = ${http_code} \n\nresponse = ${response}`)
        })
        .finally(()=>setIsLoading(false))
    }

    const PostAmoCredentials = () => {
        setIsLoading(true)
        API.Post({ method:'postamocredentials', authtoken}, {
            is_active: amoCredentialsIsActive,
            endpoint: amoCredentialsEndpoint,
            token: amoCredentialsToken,
            date_field_id: amoCredentialsDateFieldId,
            getaddress_field_id: amoCredentialsGetaddressFieldId,
            setaddress_field_id: amoCredentialsSetaddressFieldId,
            report_field_id: amoCredentialsReportFieldId,
            report_expert_field_id: amoCredentialsReportExpertFieldId
        })
        .then(res => {
            console.log( res.data )
            if (res.data.result){
                setPostAmoSuccess( true )
                setIsAmoChanged( false )
            }
        })
        .finally(()=>setIsLoading(false))
    }

    
    return (
        <>

            <Alert variant="info" className='mb-3 d-block d-md-none'>
                C настройками удобнее работать на большом экране компьютера в панели управления по адресу <a href="#">my.priemka-pro.ru</a>
            </Alert>

            <Tab.Container id="left-tabs-example" defaultActiveKey="AmoCRM">
                <Row>
                    <Col sm={3} className='mt-3 mb-5'>
                        <Nav variant="pills" className="flex-column">
                            <Nav.Item>
                                <Nav.Link eventKey="AmoCRM">AmoCRM</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="YandexDisk">Яндекс.Диск</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="Avito" disabled={true}>Авито <sup>Скоро</sup></Nav.Link>
                            </Nav.Item>
                        </Nav>
                    </Col>
                    <Col sm={9}className='mt-2'>
                        <Tab.Content>

                            <Tab.Pane eventKey="AmoCRM">
                                <h1 className='mb-4'>Интеграция с AmoCRM</h1>
                                {
                                    !isAmoFeatureAvaliable && 
                                    <Alert variant="warning" className='mb-3'>
                                        Функция доступна для тарифов Бизнес и выше.
                                    </Alert>
                                }
                                <Card>
                                    <Card.Header>
                                        <Form.Check // prettier-ignore
                                            type="switch"
                                            id="custom-switch"
                                            label={`Интеграция с AmoCRM ${(amoCredentialsIsActive ? 'подключена' : 'отключена')}`}
                                            value={true} 
                                            checked={!!+amoCredentialsIsActive && isAmoFeatureAvaliable}
                                            isValid={!!+amoCredentialsIsActive && isAmoFeatureAvaliable}
                                            onChange={(e)=> setAmoCredentialsIsActive(+e.target.checked) }
                                            disabled={!isAmoFeatureAvaliable}
                                        />
                                    </Card.Header>
                                    <Card.Body>
                                        <Card.Text>
                                            <Container className='ms-0 ps-0'>
                                                <Row>
                                                    <Col>
                                                        <FloatingLabel label="URL подключения" className='mb-3'>
                                                            <Form.Control 
                                                                id="endpoint"
                                                                type="text" 
                                                                placeholder=''
                                                                value={amoCredentialsEndpoint} 
                                                                onChange={(e)=> setAmoCredentialsEndpoint(e.target.value) }
                                                                disabled={!+amoCredentialsIsActive || !isAmoFeatureAvaliable}
                                                                />
                                                        </FloatingLabel>
                                                        <FloatingLabel label="Долгосрочный токен" className='mb-4'>
                                                            <Form.Control 
                                                                id="token" 
                                                                as="textarea"
                                                                placeholder=''
                                                                rows={3}
                                                                value={amoCredentialsToken} 
                                                                onChange={(e)=> setAmoCredentialsToken(e.target.value) }
                                                                style={{height: 150}}
                                                                disabled={!+amoCredentialsIsActive || !isAmoFeatureAvaliable}
                                                            />
                                                        </FloatingLabel>
                                                        <div className="row">
                                                            <div className="col col-12 col-md-6">
                                                                <Button
                                                                    variant="outline-primary"
                                                                    className='w-50'
                                                                    disabled={!+amoCredentialsIsActive || !isAmoFeatureAvaliable}
                                                                    onClick={ TestAmoConnection }
                                                                    size="sm"
                                                                >
                                                                    Проверить подключение
                                                                </Button>
                                                            </div>
                                                        </div>

                                                        <h5 className='mt-5 mb-3'>Забирать из AmoCRM поля:</h5>
                                                        <FloatingLabel label="Имя заказчика" className='mb-3'>
                                                            <Form.Control 
                                                                id="endpoint"
                                                                type="text" 
                                                                placeholder=''
                                                                value={'Имя контакта в сделке (автоматически)'} 
                                                                onChange={(e)=> setAmoCredentialsEndpoint(e.target.value) }
                                                                disabled={true}
                                                            />
                                                        </FloatingLabel>
                                                        <FloatingLabel label="Адрес приёмки или название ЖК (id поля в AmoCRM)" className='mb-3'>
                                                            <Form.Control 
                                                                id="endpoint"
                                                                type="text" 
                                                                placeholder=''
                                                                value={amoCredentialsGetaddressFieldId} 
                                                                onChange={(e)=> setAmoCredentialsGetaddressFieldId(e.target.value) }
                                                                disabled={!+amoCredentialsIsActive || !isAmoFeatureAvaliable}
                                                            />
                                                        </FloatingLabel>
                                                        <FloatingLabel label="Дата приёмки (id поля в AmoCRM)" className='mb-3'>
                                                            <Form.Control 
                                                                id="endpoint"
                                                                type="text" 
                                                                placeholder=''
                                                                value={amoCredentialsDateFieldId} 
                                                                onChange={(e)=> setAmoCredentialsDateFieldId(e.target.value) }
                                                                disabled={!+amoCredentialsIsActive || !isAmoFeatureAvaliable}
                                                            />
                                                        </FloatingLabel>

                                                        <h5 className='mt-5 mb-3'>Возвращать в AmoCRM в поля:</h5>
                                                        <FloatingLabel label="Полный адрес (id поля в AmoCRM)" className='mb-3'>
                                                            <Form.Control 
                                                                id="endpoint"
                                                                type="text" 
                                                                placeholder=''
                                                                value={amoCredentialsSetaddressFieldId} 
                                                                onChange={(e)=> setAmoCredentialsSetaddressFieldId(e.target.value) }
                                                                disabled={!+amoCredentialsIsActive || !isAmoFeatureAvaliable}
                                                                />
                                                        </FloatingLabel>
                                                        <FloatingLabel label="Ссылка на Акт осмотра (id поля в AmoCRM)" className='mb-3'>
                                                            <Form.Control 
                                                                id="endpoint"
                                                                type="text" 
                                                                placeholder=''
                                                                value={amoCredentialsReportFieldId} 
                                                                onChange={(e)=> setAmoCredentialsReportFieldId(e.target.value) }
                                                                disabled={!+amoCredentialsIsActive || !isAmoFeatureAvaliable}
                                                                />
                                                        </FloatingLabel>
                                                        <FloatingLabel label="Ссылка на Заключение (id поля в AmoCRM)" className='mb-3'>
                                                            <Form.Control 
                                                                id="endpoint"
                                                                type="text" 
                                                                placeholder=''
                                                                value={amoCredentialsReportExpertFieldId} 
                                                                onChange={(e)=> setAmoCredentialsReportExpertFieldId(e.target.value) }
                                                                disabled={!+amoCredentialsIsActive || !isAmoFeatureAvaliable}
                                                                />
                                                        </FloatingLabel>
                                                    </Col>
                                                </Row>
                                            </Container>
                                        </Card.Text>
                                    </Card.Body>
                                </Card>
                                <div className="row py-3">
                                    <div className="col col-12 col-md-6">
                                        <Button
                                            variant={ postAmoSuccess ? 'success' : 'primary' }
                                            className='w-100 mb-3 px-5 py-3'
                                            disabled={!isAmoChanged || !isAmoFeatureAvaliable}
                                            onClick={ PostAmoCredentials }
                                        >
                                            { postAmoSuccess ? 'Сохранено' : 'Сохранить изменения' }
                                        </Button>
                                    </div>
                                </div>
                            </Tab.Pane>

                            <Tab.Pane eventKey="YandexDisk">
                                <YandexDisk {...props} />
                            </Tab.Pane>
                            
                            <Tab.Pane eventKey="Avito">
                            <Alert variant="info" className='mb-3'>
                                    Возможность скоро будет добавлена
                                </Alert>
                            </Tab.Pane>
                        </Tab.Content>
                    </Col>
                </Row>
            </Tab.Container>
        </>
    )
}
