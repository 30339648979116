/**
 * @author Sergey Tumarkin https://t.me/stumarkin
 */
import { 
    Alert,
    FloatingLabel,
    CloseButton, 
    Form,
    Button,
    Card,
    Image,
    Container,
    Row,
    Col,
    Tab,
    Tabs,
    Nav
 } from 'react-bootstrap';
import * as API from '../data/API';
import { useState, useEffect, useCallback, useRef } from 'react';
import YandexDisk from './Connections/YandexDisk';
import Landing from './Connections/Landing';
import SortableList, { SortableItem } from "react-easy-sort";
import arrayMove from "array-move";
import * as Config from '../data/Config';


export default function SettingsLanding ( props ){
    const {
        authtoken,
        account
    } = props

    const [isLoading, setIsLoading] = useState(false)

    
    return (
        <>
            

            <Alert variant="info" className='mb-3 d-block d-md-none'>
                C настройками удобнее работать на большом экране компьютера в панели управления по адресу <a href="#">my.priemka-pro.ru</a>
            </Alert>

            <Tab.Container id="left-tabs-example" defaultActiveKey="Landing">
                <Row>
                    <Col sm={3} className='mt-3 mb-5'>
                        <Nav variant="pills" className="flex-column">
                            <Nav.Item>
                                <Nav.Link eventKey="Landing">Лендинг</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="Bot" disabled={true}>Telegram-бот <sup>Скоро</sup></Nav.Link>
                            </Nav.Item>
                        </Nav>
                    </Col>
                    <Col sm={9} className='mt-2'>
                        <Tab.Content>
                            <Tab.Pane eventKey="Landing">
                                <Landing {...props} />
                            </Tab.Pane>
                            
                            <Tab.Pane eventKey="Avito">
                            <Alert variant="info" className='mb-3'>
                                    Возможность скоро будет добавлена
                                </Alert>
                            </Tab.Pane>
                        </Tab.Content>
                    </Col>
                </Row>
            </Tab.Container>
        </>
    )
}
