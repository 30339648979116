/**
 * @author Sergey Tumarkin https://tumarkin.me
 */

export const Domain = "https://priemka-pro.ru";
export const ApiPath = "/api/admin/";
export const AmplitudeKey = 'c8698f1fccc72a1744388b9e1341b833';
export const FeatureToggles = {
     signupAvaliable: true, 
     chief: false, //realease after 30.01.2025
     reportImagesToggle: false,
     showUserAuths: false,
     showFormsFilterCommentedForms: false,
     aiClause: true,
};

// export const Domain = "https://priemka.msk.ru";
// export const ApiPath = "/api/admin_v2.8/";
// export const AmplitudeKey = 'f25a5c79e090b04161ab6d54246d390a';
// export const FeatureToggles = {
//     signupAvaliable: false, 
//     chief: true, //realease after 30.01.2025
//     reportImagesToggle: false,
//     showUserAuths: false,
//     showFormsFilterCommentedForms: false,
//     aiClause: false,
//     pdfHide: true,
//     menuHideIntegration: true,
//     menuHidePlans: true,
//     menuHideHelp: true,
//     menuHideReestr: true,
// };
    
// export const Domain = "https://specnovostroy.app";
// export const ApiPath = "/api/admin/";
// export const AmplitudeKey = '383cad980cbad16bd7a1cc3c86b41543';
// export const FeatureToggles = {
//     signupAvaliable: false, 
//     chief: true,
//     reportImagesToggle: true,
//     showUserAuths: true,
//     showFormsFilterCommentedForms: true,
//     aiClause: false,
//};
